module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"virtual-engineering","short_name":"ve","start_url":"/","background_color":"#FFFFFF","theme_color":"#E92733","display":"minimal-ui","icon":"src/assets/images/ve-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cdad71302681f00dcacdf734f209feb4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://virtualengineering.network.s-z.se/graphql","type":{"GfEntries":{"exclude":true},"GfEntry":{"exclude":true},"GfForm":{"exclude":true},"GfForms":{"exclude":true},"GfSettings":{"exclude":true},"GfSubmittedEntries":{"exclude":true},"GfSubmittedEntry":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-102},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":["Poppins","Inter"],"file":"https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Inter:wght@400;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-P7VZQNX","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["sv","en"],"defaultLanguage":"sv","siteUrl":"https://virtualengineering.se","redirect":false,"i18nextOptions":{"fallbackLng":"sv","supportedLngs":["sv","en"],"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?","getLanguageFromPath":true,"excludeLanguages":["sv","en"]},{"matchPath":"/:lang?/:parent","getLanguageFromPath":true,"excludeLanguages":["sv","en"]},{"matchPath":"/:lang?/:parent/:child","getLanguageFromPath":true,"excludeLanguages":["sv","en"]},{"matchPath":"/:lang?/:parent/:parent/:child","getLanguageFromPath":true,"excludeLanguages":["sv","en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
